/**
 * Gloabal State, store initial loading progreass
 */

import { create } from "zustand";
import { subscribeWithSelector } from "zustand/middleware";

export default create(subscribeWithSelector((set) => {
    return {
        progress: 0, // Initial prograss
        finished: false,
        setProgress: (num) => {

            set((state) => {
                return {
                    progress: num
                }
            })
        },
        setFinish: () => {

            set((state) => {
                // console.log('finish');
                return {
                    finished: true
                }
            })
        },


    }
}))