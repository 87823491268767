import { About, AboutModel } from "./Projects/About"
import { BYOW, BYOWModel } from "./Projects/BYOW"
import { ClifPavilion, ClifPavilionModel } from "./Projects/ClifPavilion"
import { ClothSimulation, ClothSimulationModel } from "./Projects/ClothSimulation"
import { ColorWheel, ColorWheelModel } from "./Projects/ColorWheel"
import { Contact, ContactModel } from "./Projects/Contact"
import { MeshEditor, MeshEditorModel } from "./Projects/MeshEditor"
import { PathTracer, PathTracerModel } from "./Projects/PathTracer"
import { PlasticScape, PlasticScapeModel } from "./Projects/PlasticScape"
import { Resterizer, ResterizerModel } from "./Projects/Resterizer"
import LocationCalculation from "./Utils/LocationCalculation"
import ProjectPath from "./Utils/ProjectPath"



/**
 * 
 * Component that creat project loader and creat each instances
 */

export default function Projects(props) {
    /**
     * all modeling processing functions
     */
    const materialManager = props.materialManager


    /**
     * projects
     */
    const plasticScape = ProjectPath.plasticScape
    const colorWheel = ProjectPath.colorWheel
    const BYOW_path = ProjectPath.BYOW
    const clifPavilion = ProjectPath.clifPavilion
    const clothSimulation = ProjectPath.clothSimulation
    const meshEditor = ProjectPath.meshEditor
    const resterizer = ProjectPath.resterizer
    const pathTracer = ProjectPath.pathTracer

    const about = ProjectPath.about
    const contact = ProjectPath.contact





    return (
        <>
            <PathTracer model={pathTracer.model} material={materialManager.getMaterial(pathTracer.type)}>
                {LocationCalculation(pathTracer.position).map((pos, inx) => (
                    <PathTracerModel
                        key={`pathTracer_${inx}`}
                        position={[pos.x, pos.y, pos.z]}
                        color={materialManager.computer_graphic.color}
                    />
                ))}
            </PathTracer>

            <Resterizer model={resterizer.model} material={materialManager.getMaterial(resterizer.type)}>
                {LocationCalculation(resterizer.position).map((pos, inx) => (
                    <ResterizerModel
                        key={`resterizer_${inx}`}
                        position={[pos.x, pos.y, pos.z]}
                        color={materialManager.computer_graphic.color}
                    />

                ))}
            </Resterizer>
            <PlasticScape model={plasticScape.model} >
                {LocationCalculation(plasticScape.position).map((pos, inx) => (
                    <PlasticScapeModel
                        key={`PlasticScape_${inx}`}
                        position={[pos.x, pos.y, pos.z]}
                        color={materialManager.architecture.color}
                    />


                ))}
            </PlasticScape>

            <MeshEditor model={meshEditor.model} >
                {LocationCalculation(meshEditor.position).map((pos, inx) => (
                    <MeshEditorModel
                        key={`meshEditor_${inx}`}
                        position={[pos.x, pos.y, pos.z]}
                        color={materialManager.computer_graphic.color}
                    />

                ))}
            </MeshEditor>
            <ClothSimulation model={clothSimulation.model} material={materialManager.getMaterial(clothSimulation.type)}>
                {LocationCalculation(clothSimulation.position).map((pos, inx) => (
                    <ClothSimulationModel
                        key={`clothSimulation_${inx}`}
                        position={[pos.x, pos.y, pos.z]}
                        color={materialManager.computer_graphic.color}
                    />
                ))}

            </ClothSimulation>

            <BYOW model={BYOW_path.model} material={materialManager.getMaterial(BYOW_path.type)}>
                {LocationCalculation(BYOW_path.position).map((pos, inx) => (
                    <BYOWModel
                        key={`BYOW_${inx}`}
                        position={[pos.x, pos.y, pos.z]}
                        color={materialManager.programing.color}
                    />)
                )}

            </BYOW>

            <ClifPavilion model={clifPavilion.model} material={materialManager.getMaterial(clifPavilion.type)}>
                {LocationCalculation(clifPavilion.position).map((pos, inx) => (

                    <ClifPavilionModel
                        key={`clifPavilion_${inx}`}
                        position={[pos.x, pos.y, pos.z]}
                        color={materialManager.architecture.color}
                    />)
                )}
            </ClifPavilion>

            <ColorWheel model={colorWheel.model} material={materialManager.getMaterial(colorWheel.type)}>
                {LocationCalculation(colorWheel.position).map((pos, inx) => (
                    <ColorWheelModel
                        key={`colorWheel_${inx}`}
                        position={[pos.x, pos.y, pos.z]}
                        color={materialManager.product.color}
                    />)
                )}
            </ColorWheel>

            <About resource={about} material={materialManager.getMaterial(about.type)}>
                {LocationCalculation(about.position).map((pos, inx) => (
                    <AboutModel
                        key={`about_${inx}`}
                        position={[pos.x, pos.y, pos.z]}
                        color={materialManager.special.color}
                    />
                ))}

            </About>

            <Contact resource={contact} material={materialManager.getMaterial(contact.type)}>
                {LocationCalculation(contact.position).map((pos, inx) => (
                    <ContactModel
                        key={`contact_${inx}`}
                        position={[pos.x, pos.y, pos.z]}
                        color={materialManager.special.color}
                    />
                ))}
            </Contact>
        </>
    )

} 