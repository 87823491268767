import { animated, useTransition } from "react-spring"
import useLoadingProgress from "../Utils/useLoadingProgress"

export default function LoadingPage() {
    const progress = useLoadingProgress((state) => state.progress)
    // console.log(progress)
    const value = progress.toFixed(0)
    const fillerStyles = {
        width: `${progress}%`,
    }
    const transition = useTransition(progress, {
        from: { opacity: 1 },
        enter: { opacity: 1 },
        leave: { opacity: 0 }
    })
    return transition((style) => (
        <animated.div style={style} className="whole_page">
            <div className="container">
                <div className="filler" style={fillerStyles}>
                    <span className="lable">
                        {value} % loaded
                    </span>
                </div>
            </div>
        </animated.div>

    ))
}