import { OrbitControls, PerspectiveCamera } from "@react-three/drei";
import { useFrame, useThree } from "@react-three/fiber";
import { useState } from "react";
import { useEffect } from "react";

import { useRef } from "react";
import * as THREE from 'three'





export default function CameraControls() {
    const [mobileMode, setMobileMode] = useState(false)
    const basic = useThree()


    const control = useRef()

    //Check onece when scene load. If the viewport width <700px, it will be a mobile phone, the rotate speed will be fast
    if (!mobileMode && basic.viewport.width < 700) {
        setMobileMode(true)
    }


    const int_cameraTarget = new THREE.Vector3(0, 0, 0)

    //infinity effect setting
    const mergeConst = 6
    const jumpConst = 12

    //new target store


    const loadingAction = () => {
        control.current.object.position.y.lerp(y.set(1), 1)

    }

    let rotateSpeed = 0.2
    useEffect(() => {
        if (mobileMode) {
            rotateSpeed = 0.5
        }

    }, [mobileMode])
    /*check the control target,
       if the x or z coordinator is out of the boundary setting(mergeConst)
       the camara location and target location jump back to another side
       the jump distace is defined by jumpconst
       */
    /**
     * 
     * we check "target" not "position". Because we use OrbitControl, the camera always rotatate around a target point.
     * if we check 
     */
    const boundCheck = (target, position) => {


        if (target.x > mergeConst) {
            target.x -= jumpConst
            position.x -= jumpConst
        }
        if (target.x < -mergeConst) {
            target.x += jumpConst
            position.x += jumpConst
        }
        if (target.z > mergeConst) {
            target.z -= jumpConst
            position.z -= jumpConst
        }
        if (target.z < -mergeConst) {
            target.z += jumpConst
            position.z += jumpConst
        }
    }

    useFrame((state) => {

        boundCheck(control.current.target, control.current.object.position)
        // console.log(state);

    })

    return <>


        <OrbitControls

            target0={int_cameraTarget}
            ref={control}
            enableDamping={true}
            screenSpacePanning={false}
            maxPolarAngle={Math.PI / 2.2}
            minPolarAngle={Math.PI / 8}
            rotateSpeed={rotateSpeed}
            panSpeed={1}
            mouseButtons={
                {
                    LEFT: THREE.MOUSE.PAN,
                    MIDDLE: THREE.MOUSE.DOLLY,
                    RIGHT: THREE.MOUSE.ROTATE
                }
            }
            touches={
                {
                    ONE: THREE.TOUCH.PAN,
                    TWO: THREE.TOUCH.DOLLY_ROTATE
                }
            }


        />
    </>
}