
import { animated, useSpring, useTransition } from "@react-spring/web";



import useModalPage from "../Utils/useModalPage";
import AboutModal from "./components/AboutModal";
import ContactModal from "./components/ContactModal";
import ProjectModal from "./components/ProjectModal";


export default function Modal(props) {

    /*Global State */
    const showModal = useModalPage((state) => state.showModal)//check if modal page show
    const project = useModalPage((state) => state.projectName)//check which project on show
    const closeModal = useModalPage((state) => state.closeModal)//close modal function

    /**
     * Transition Animation: Mount/Unmount
     */
    const transition = useTransition(showModal, {

        from: { opacity: 0 },
        enter: { opacity: 1 },
        leave: { opacity: 0.01 }

    })


    let page = <ProjectModal project={project} closeFuntion={closeModal} />
    if (project === "about") {
        page = <AboutModal project={project} closeFuntion={closeModal} />
    }
    else if (project === "contact") {
        page = <ContactModal project={project} closeFuntion={closeModal} />
    }
    return transition((style, item) => (
        item && <animated.div style={style}>{page}</animated.div>
    ))

    // return showModal && <div>{page}</div>

}