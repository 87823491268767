import { useFrame } from "@react-three/fiber";
import * as THREE from 'three'

const vec = new THREE.Vector3()
export default function MovingCamera(props) {

    useFrame(((state) => {
        // console.log(state);
        state.clock.elapsedTime < 5 && state.camera.position.lerp(vec.set(7, 1, 0), 0.02)
    }), 0)
    return null
}