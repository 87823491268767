import ProjectPath from "../../Utils/ProjectPath"
import ParagraphText from "./ParagraphText"
import SocialMediaIcon from "./SocialMediaIcon"

export default function AboutModal(props) {
    /**Local data */
    const projectData = ProjectPath[`${props.project}`]
    const photo = projectData.photo
    const icons = projectData.icons
    const intro = projectData.intro


    return <div className="modal_background">
        <div className="close_btn" onClick={props.closeFuntion}>∅</div>
        <div className="about_page_area">
            <div className="photo_contact_area">
                <img className="profile_photo" src={photo} />
                <a
                    className="email_address"
                    href="mailto:yustevenzhang.info@gmail.com"
                    target="_blank"
                    rel="noreferrer noopener"
                >
                    yustevenzhang.info@gmail.com
                </a>
                <div className="socialIcon_area">
                    {icons.map((icons, inx) => (
                        <SocialMediaIcon
                            key={inx}
                            data={icons}
                        />
                    ))}

                </div>
            </div>
            <div className="intro_area">
                {intro.map((para, inx) => (
                    <ParagraphText
                        key={inx}
                        data={para}
                    />
                ))}

            </div>
        </div>
    </div>
}