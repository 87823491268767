export default {
    /**
     * example empty templete. used for component first loading.
     */
    empty: {
        images: [
            {
                name: ' ',
                path: ' ',
            },

        ],
        title: {
            title: '',
            type: ''
        },
        informations: [
            {
                name: ' ',
                text: ' '
            },

        ],
        text: " "
    },
    cities:
        [
            {
                name: 'city01',
                type: 'city',
                id: '01',
                position: [3, 0, -3],
                model: "static/models/city/cityPart01.glb",
                lotTexture: "static/models/city/roadTexture01.jpg",
                buildingTexture: "static/models/city/cityTexture01.jpg",
            },
            {
                name: 'city02',
                type: 'city',
                id: '02',
                position: [3, 0, 3],
                model: "static/models/city/cityPart02.glb",
                lotTexture: "static/models/city/roadTexture02.jpg",
                buildingTexture: "static/models/city/cityTexture02.jpg",
            },

            {
                name: 'city03',
                type: 'city',
                id: '03',
                position: [-3, 0, 3],
                model: "static/models/city/cityPart03.glb",
                lotTexture: "static/models/city/roadTexture03.jpg",
                buildingTexture: "static/models/city/cityTexture03.jpg",
            },
            {
                name: 'city04',
                type: 'city',
                id: '04',
                position: [-3, 0, -3],
                model: "static/models/city/cityPart04.glb",
                lotTexture: "static/models/city/roadTexture04.jpg",
                buildingTexture: "static/models/city/cityTexture04.jpg",
            }
        ],



    colorWheel: {
        id: 'colorWheel',
        type: 'product',
        position: [-2.94, 0.19, -3.01],
        model: "static/models/Projects/colorWheel.glb",
        images: [
            {
                name: 'Final Prototype',
                path: 'static/projects/colorWheel/colorWheel001.jpg',
            },
            {
                name: 'Color-Sound Matching Table',
                path: 'static/projects/colorWheel/colorWheel002.jpg',
            },
            {
                name: 'Model Diagram',
                path: 'static/projects/colorWheel/colorWheel003.jpg',
            },
        ],
        title: {
            title: 'Color Wheel',
            type: 'Product'
        },
        informations: [
            {
                name: 'Type',
                text: 'Product'
            },
            {
                name: 'Collaborator',
                text: 'Elnaz Tafrihi, Shuang Yan'
            },
            {
                name: 'Year',
                text: '2019'
            },
        ],
        text: "Teamed with Elnaz Tafrihi and Shuang Yan, We try to design a new instrument that people can use color to play music.The color sensor on the instrument will detect the color on the disk and play the sound.People can change the combination of the color cube to compose the music.Final product implemented with Arduino and C++."
    },
    BYOW: {
        id: 'BYOW',
        type: 'programing',
        position: [1.43, 0.29, 3.63],
        model: "static/models/Projects/BYOW.glb",
        images: [
            {
                name: 'Generating maze with random seeds',
                path: 'static/projects/BYOW/BYOW001.jpg',
            },
            {
                name: 'The plyer can only see part of the map',
                path: 'static/projects/BYOW/BYOW002.jpg',
            },
            {
                name: 'The map with "bridge building" and "deleting wall" chances',
                path: 'static/projects/BYOW/BYOW003.jpg',
            },
            {
                name: 'The Basic Logic to Generating Map',
                path: 'static/projects/BYOW/BYOW004.jpg',
            },

        ],
        title: {
            title: 'Build Your Own World',
            type: 'Other Programming'
        },
        informations: [
            {
                name: 'Type',
                text: 'Other Programming'
            },

            {
                name: 'Year',
                text: '2020'
            },
        ],
        text: "The final project in CS61B Data Structure class. I use Java to design an algorithm that will generate a dungeon map by giving seeds. I also designed basic UI and also some interaction with the characters. "
    },

    clifPavilion: {
        id: 'clifPavilion',
        type: 'architecture',
        position: [4.95, 0.33, 5.69],
        model: "static/models/Projects/clifPavilion.glb",
        images: [
            {
                name: 'Final Prototype Support Reaction And Stress [kN]',
                path: 'static/projects/clifPavilion/clifPavilion001.jpg',
            }, {
                name: 'Basic Design Strategy',
                path: 'static/projects/clifPavilion/clifPavilion002.jpg',
            }, {
                name: 'Galapagos Optimization',
                path: 'static/projects/clifPavilion/clifPavilion003.jpg',
            }, {
                name: 'Three Optizizations',
                path: 'static/projects/clifPavilion/clifPavilion004.jpg',
            }, {
                name: 'Final Structure Analysis',
                path: 'static/projects/clifPavilion/clifPavilion005.jpg',
            },


        ],
        title: {
            title: 'Pavilion on the Clif',
            type: 'Architecture'
        },
        informations: [
            {
                name: 'Type',
                text: 'Other Programming'
            },
            {
                name: 'Collaborator',
                text: 'Tya Abe, Sam Gebb'
            },
            {
                name: 'Year',
                text: '2020'
            },
        ],
        text: "Teamed with Tya Abe and Sam Gebb, in this structure analysis project, We designed a cantilever pavilion on the cliff. We use Karamba3D to generate the structure performance and use it as the constraint to let Galapagos find the possible geometry solutions. "

    },

    clothSimulation: {
        id: 'clothSimulation',
        type: 'computer_graphic',
        position: [2.7, 0.34, 1.61],
        model: "static/models/Projects/clothSimulation.glb",
        images: [
            {
                name: 'Fabric with Wind',
                path: 'static/projects/clothSimulation/clothSimulation001.PNG',
            },
            {
                name: 'Cloth Structure System',
                path: 'static/projects/clothSimulation/clothSimulation002.jpg',
            }, {
                name: 'Gravity Simulation via Numerical Integration',
                path: 'static/projects/clothSimulation/clothSimulation003.jpg',
            },
            {
                name: 'Collision with Other Geometries',
                path: 'static/projects/clothSimulation/clothSimulation004.jpg',
            },
            {
                name: 'Shader System',
                path: 'static/projects/clothSimulation/clothSimulation005.jpg',
            },
            {
                name: 'Wind Simulation',
                path: 'static/projects/clothSimulation/clothSimulation006.jpg',
            },
        ],
        title: {
            title: 'CLOTH SIMULATION',
            type: 'Computer Graphic'
        },
        informations: [
            {
                name: 'Type',
                text: 'Computer Graphic'
            },
            {
                name: 'Year',
                text: '2021'
            },
        ],
        text: "A C++ simulation system to simulate the movement of the cloth. The system represents cloth as grids with the mass on each point and spring on each line. The system can simulate gravity, friction, and collisions with other geometries. There are also basic shaders coded with GLSL to allow the simulation scene to be rendered in real-time. "

    },

    meshEditor: {
        id: 'meshEditor',
        type: 'computer_graphic',
        position: [5.55, 0.26, -2.41],
        model: "static/models/Projects/meshEditor.glb",
        images: [
            {
                name: 'Bezier Curves and Surfaces',
                path: 'static/projects/meshEditor/meshEditor001.jpg',
            }, {
                name: 'Half-Edge and Normal Sampling to enable smooth shading',
                path: 'static/projects/meshEditor/meshEditor002.jpg',
            }, {
                name: 'Edge Flip and Split',
                path: 'static/projects/meshEditor/meshEditor003.jpg',
            }, {
                name: 'Loop Subdivision For Mesh Upsampling',
                path: 'static/projects/meshEditor/meshEditor004.jpg',
            },


        ],
        title: {
            title: 'Mesh Editor',
            type: 'Computer Graphic'
        },
        informations: [
            {
                name: 'Type',
                text: 'Computer Graphic'
            },
            {
                name: 'Collaborator',
                text: 'Robert Qiu'
            },
            {
                name: 'Year',
                text: '2021'
            },
        ],

        text: "In this project, mypartner Robert Qiu and I implemented an editor that can show and change theBezier Curves and Mesh Geometry. In Bezier curves part, de Casteljau'salgorithm is used to calculate curve shape. The half-edge structure is used tostore mesh data, so the edges on each surface can be deleted and added."

    },
    pathTracer: {
        id: 'pathTracer',
        type: 'computer_graphic',
        position: [4.83, 0.02, -4.72],
        model: "static/models/Projects/pathTracer.glb",
        images: [
            {
                name: 'Render Result',
                path: 'static/projects/pathTracer/pathTracer001.png',
            }, {
                name: 'BVH Structure and Normal Rendering',
                path: 'static/projects/pathTracer/pathTracer002.jpg',
            }, {
                name: 'Ray Tracing and GI',
                path: 'static/projects/pathTracer/pathTracer003.jpg',
            }, {
                name: 'Depth of Field',
                path: 'static/projects/pathTracer/pathTracer004.jpg',
            }, {
                name: 'Microfacet Matiral',
                path: 'static/projects/pathTracer/pathTracer005.jpg',
            },


        ],
        title: {
            title: 'Ray Tracing Render',
            type: 'Computer Graphic'
        },
        informations: [
            {
                name: 'Type',
                text: 'Computer Graphic'
            },
            {
                name: 'Year',
                text: '2021'
            },
        ],

        text: "A C++ Raytracing render Engine. It uses the basicpath tracing algorithm to render the scene. The render uses BVH structure to optimize theray-triangle intersection time and can support GI and Adaptive Sampling. It canalso achieve the depth of field effect to simulate the physical camera focus point.The material can be microfacet material  "

    },
    plasticScape: {
        id: 'plasticScape',
        type: 'architecture',
        position: [-3.63, 0.02, -0.88],
        model: "static/models/Projects/plasticScape.glb",
        images: [
            {
                name: 'Community Emergency Building Hub',
                path: 'static/projects/plasticScape/plasticScape001.jpg',
            }, {
                name: 'Drone Based Plastic Building',
                path: 'static/projects/plasticScape/plasticScape002.jpg',
            },
            {
                name: 'Temporary Structure to Change LandScape',
                path: 'static/projects/plasticScape/plasticScape003.jpg',
            },
            {
                name: 'Nature and Urban in Same Landscape',
                path: 'static/projects/plasticScape/plasticScape004.jpg',
            },
            {
                name: 'Interior and Exterior Merging',
                path: 'static/projects/plasticScape/plasticScape005.jpg',
            },


        ],
        title: {
            title: 'PLASTIC-SCAPE',
            type: 'Architecture'
        },
        informations: [
            {
                name: 'Type',
                text: 'Architecture'
            },
            {
                name: 'Collaborator',
                text: 'Nikolaos Romanos Tsokas'
            },
            {
                name: 'Year',
                text: '2021'
            },
        ],

        text: `
                More than 300 million tons of plastic are produced each year, of which only 18% are recycled. Our project suggests utilization of such unrated resources, approaching global warming as a chance to restore the balance between human living space and natural habitat of other species.
                To achieve that, recycling plastics replace construction materials. A small recycling apparatus is installed in each community while other machines are used to remold plastic trash in a form that a drone-based 3d printer can use. From now on, these drones will take over construction of temporary infrastructures that will shape the dryland following the frequently occurring floods. As sea level is gradually rising, the infrastructure also will increase in height layer by layer. 
                These plastic infrastructures will also serve as scaffolds for plants to grow. Soil and sand coming from the floods will deposit on the infrastructures supplying them with nutrients. As time passes, plants and plastic structures will form the new landscape and will reshape current city networks. Nature will intrude the originally isolated interiors of buildings while old man-made infrastructures will provide habitat for other species.
                Rather than seeking to preserve the human living environment, the consequence of global warming might be a chance to invite nature into human infrastructure again. After many years of negotiation and reshaping, the human living space and nature will achieve a new balance.
                `
    },

    resterizer: {
        id: 'resterizer',
        type: 'computer_graphic',
        position: [-3.87, 0.56, 1.8],
        model: "static/models/Projects/resterizer.glb",
        images: [
            {
                name: 'Rasterizing triangle and coloring with Barycentric coordinates',
                path: 'static/projects/rasterizer/rasterizer001.jpg',
            }, {
                name: 'Transforms Matrix',
                path: 'static/projects/rasterizer/rasterizer002.jpg',
            }, {
                name: 'Pixel sampling:Bilinear Sampling',
                path: 'static/projects/rasterizer/rasterizer003.jpg',
            }, {
                name: 'Level sampling: Nearst & Bilinear',
                path: 'static/projects/rasterizer/rasterizer004.jpg',
            },

        ],
        title: {
            title: 'Rasterizer',
            type: 'Computer Graphic'
        },
        informations: [
            {
                name: 'Type',
                text: 'Computer Graphic'
            },
            {
                name: 'Collaborator',
                text: 'Robert Qiu'
            },
            {
                name: 'Year',
                text: '2021'
            },
        ],

        text: "A Rasterizer implemented with C++. can raster points, lines, and triangles with textures"

    },

    about: {
        name: 'About',
        type: 'special',
        id: 'about',
        position: [1.16, 0.79, 0.9],
        model: 'static/models/Projects/name.glb',
        photo: 'static/others/photos1.jpg',
        icons: [
            {
                name: 'Linkedin',
                icon: 'static/others/linkedin-50.png',
                link: 'https://www.linkedin.com/in/sz0616/'
            }, {
                name: 'Instagram',
                icon: 'static/others/instagram-50.png',
                link: 'https://www.instagram.com/irrelevantthin/'
            }, {
                name: 'Github',
                icon: 'static/others/github-50.png',
                link: 'https://github.com/YuZhang-steven'
            }, {
                name: 'Twitter',
                icon: 'static/others/twitter-50.png',
                link: 'https://twitter.com/yu_steven_zhang'
            }, {
                name: 'Anti-WeChat',
                icon: 'static/others/anti-wechat-50.png',
                link: 'https://www.google.com/search?q=Why+you+should+not+use+WeChat'
            },
        ],
        intro: [
            `If you think long reading wastes your time, I feel you! Just hit up those icons below my photo.`,

            `Ready to know more? Let’s do it!`,

            `My name is Yu Zhang(张钰). 
            I know it is difficult to pronounce and none of my teachers said it right the first day when I was in elementary school. 
            That's why I often go by Steven.`,

            `First, thanks for being patient while this website loads. 
            Some may argue that a site like this is not effective in delivering information or optimizing performance from both design and engineering perspectives.  
            And you know what? I agree with them! However, I bet you also would love to navigate in an interactive LEGO city just like I would.
            I know it, because I have seen so many adults get completely lost in Disneyland when I was working there. 
            So, creating something playful has become my goal in recent years.`,

            `Rather than finding the “right” solution, I believe that redefining problems from various perspectives is the key to unlocking creativity. 
            My design practice has taught me this lesson, and it is the reason I regard the development of this website as a creation of experience. 
            I also believe learning new tools is the way to build those perspectives, so I enjoy learning during this creation. 
            Actually, my design background has led me to acquire knowledge in various tools, including coding. 
            I took the CS class in college because I want to know how the 3D modeling software I used every day works behind the interface.`,

            `Another question people always ask is whether LEGO is the reason I study architecture. 
            The answer is no, it was actually The Sims. 
            I still enjoy building ugly houses and cities in games like The Sims, SimCity, and Civilization. 
            Besides this, I like to read books about economics and politics until I fall asleep. 
            Oh, recently, I've been spending a lot of time in the gym to figure out how to do backflips and giants. 
            Any thoughts? Slide into my DMs and let me know!`,

        ]

    },
    contact: {
        name: 'Contact',
        type: 'special',
        id: 'contact',
        position: [1.06, 0.21, -2.4],
        model: 'static/models/Projects/contact.glb',

    },


}