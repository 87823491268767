import { createRoot, ReactDom } from 'react-dom/client'
import { Canvas } from '@react-three/fiber'
import './style.css'
import Experience from './Experience'
import Modal from './Pages/Modal'
import DropDown from './Pages/DropDown'
import MaterialManager from "./Utils/MaterialManager";
import * as THREE from 'three'

import BigTitle from './Pages/components/BigTitle';
import LoadingPageWrap from './LoadingPageWrap'





const root = createRoot(document.querySelector('#root'))

/**
 * save all tool function in utils, provide it in index with useContext()
 */

const materialManager = new MaterialManager()


root.render(
    <>
        <LoadingPageWrap />
        <BigTitle />
        <Canvas
            camera={{
                fov: 35,

                near: 0.1,
                far: 100,//defualt 10
                position: [5, 16, 0]
            }}
            shadows={true}
            gl={{
                antialias: true,
                toneMapping: THREE.ACESFilmicToneMapping,
                //outputEncoding: THREE.LinearEncoding
            }}
        // flat
        >
            <Experience materialManager={materialManager} />

        </Canvas>
        <Modal />
        <DropDown />

    </>

)
