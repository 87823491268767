import { useState } from "react"
import useModalPage from "../Utils/useModalPage"
import useProjectColor from '../Utils/useProjectColor'

export default function DropDown(props) {

    /**
     * Gloabal State
     */
    const showModal = useModalPage((state) => state.setModalPage)

    const [manu, setManu] = useState(false)
    let nextManuState = !manu
    const [projectsManu, setprojectsManu] = useState(false)

    const showProduct = useProjectColor((state) => state.showProductProj)
    const showArchitecture = useProjectColor((state) => state.showArchitectureProj)
    const showOtherPrograming = useProjectColor((state) => state.showOtherProgramingProj)
    const showComputerGraphic = useProjectColor((state) => state.showComputerGraphicProj)
    const showAll = useProjectColor((state) => state.showAll)

    const clickBtn = () => {
        setManu(nextManuState)
        if (!nextManuState) {
            setprojectsManu(false)
            showAll()
        }
        nextManuState = !nextManuState

    }

    const clickProject = () => {
        setprojectsManu(!projectsManu)
    }

    return <div className="dropdown">

        <div className="dropbtn" onClick={clickBtn} />
        {
            manu && (
                <div className="myDropdown">
                    <div className="dropdown_content" onClick={() => { showModal('about') }}>
                        ABOUT
                    </div>
                    <div id="manu_project" className="dropdown_content" onClick={clickProject}>
                        PROJECTS
                    </div>
                    {
                        projectsManu && (

                            <div >
                                <div className="project_collection" onClick={showAll}>
                                    ALL
                                </div>
                                <div className="project_collection" onClick={showComputerGraphic}>
                                    COMPUTER GRAPHIC
                                </div>
                                <div className="project_collection" onClick={showOtherPrograming}>
                                    OTHER PROGRAMING
                                </div>
                                <div className="project_collection" onClick={showArchitecture}>
                                    ARCHITECTURE
                                </div>
                                <div className="project_collection" onClick={showProduct}>
                                    PRODUCT
                                </div>
                            </div>


                        )
                    }
                </div>
            )

        }


    </div>


}