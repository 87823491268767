/**
 * Control the Modal page state:
 * show/hide, which project, web version/ mobile version
 * 
 * 
 * 
 */

import { create } from "zustand";
import { subscribeWithSelector } from "zustand/middleware";

export default create(subscribeWithSelector((set) => {
    return {
        showModal: false, //check if modal page show, the defalte is false
        projectName: "empty", //default empty, don't show anything. or show project name
        WebMobile: "web",


        setModalPage: (project) => {
            set((state) => {
                return {
                    projectName: project,
                    showModal: true
                }
            })
        },

        closeModal: () => {
            set((state) => {
                return {
                    showModal: false

                }
            })
        },

        openModal: () => {
            set((state) => {
                return {
                    showModal: true
                }
            })
        }
    }
}))