import { create } from "zustand";
import { subscribeWithSelector } from 'zustand/middleware'

export default create(subscribeWithSelector((set) => {
    return {
        computerGraphicColor: true,
        otherProgramingColor: true,
        architectureColor: true,
        productColor: true,
        otherColor: true,

        showAll: () => {
            set((state) => {
                return {
                    computerGraphicColor: true,
                    otherProgramingColor: true,
                    architectureColor: true,
                    productColor: true,
                    otherColor: true,
                }
            })
        },

        showComputerGraphicProj: () => {
            set((state) => {
                return {
                    computerGraphicColor: true,
                    otherProgramingColor: false,
                    architectureColor: false,
                    productColor: false,
                    otherColor: false,
                }
            })
        },

        showOtherProgramingProj: () => {
            set((state) => {
                return {
                    computerGraphicColor: false,
                    otherProgramingColor: true,
                    architectureColor: false,
                    productColor: false,
                    otherColor: false,
                }
            })
        },

        showArchitectureProj: () => {
            set((state) => {
                return {
                    computerGraphicColor: false,
                    otherProgramingColor: false,
                    architectureColor: true,
                    productColor: false,
                    otherColor: false,
                }
            })
        },

        showProductProj: () => {
            set((state) => {

                return {
                    computerGraphicColor: false,
                    otherProgramingColor: false,
                    architectureColor: false,
                    productColor: true,
                    otherColor: false,
                }
            })
        },

    }
}))