import { useEffect, useState } from "react"

export default function SlidesBtn(props) {
    const [lightColor, setLightColor] = useState(false)
    const classname = lightColor ? "slide_btn_hover" : "slide_btn"
    useEffect(() => {
        if (props.index !== props.picNo) {
            setLightColor(false)
        } else {
            setLightColor(true)
        }
    }, [props.picNo])


    const changeToLightColor = () => {
        setLightColor(true)

    }

    const changeToDarkColor = () => {

        if (props.index !== props.picNo) {
            setLightColor(false)
        }
    }

    return <>
        <span
            className={classname}
            onClick={props.clickFunction}

            onMouseEnter={changeToLightColor}
            onMouseLeave={changeToDarkColor}
        />

    </>


}