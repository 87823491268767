/**
 * City model loading temple
 * 
 * the props include the object from path manager, include all the model parts and
 * material location
 * 
 * 
 * 
 * The useGLTF and useTexture find location and load all the models and give texture.
 * 
 * check auto model partition tool on following website:https://github.com/pmndrs/gltfjsx
 * 
 */



/*
Auto-generated by: https://github.com/pmndrs/gltfjsx
*/
import { Instance, Instances, useGLTF } from "@react-three/drei";
import { useTexture } from "@react-three/drei";
import LocationCalculation from "../Utils/LocationCalculation";


export default function CityModel(props) {

    // console.log(props)
    const resource = props.resource // the object not directly in the props. the pros name is resource
    const { nodes } = useGLTF(resource.model) //gltf loder load as different parts

    const id = resource.id // retrive the special id number


    //loading texture, check useTexture doc for syntex. the material has to flip in Y direction
    const [buildingMap, roadMap] = useTexture([
        resource.buildingTexture,
        resource.lotTexture
    ], ([buildingMap, roadMap]) => {
        buildingMap.flipY = false
        roadMap.flipY = false
    })


    const locations = LocationCalculation(resource.position)



    return (

        <group dispose={null}>

            <Instances
                geometry={nodes[`road${id}`].geometry}
                receiveShadow
                castShadow
                /**
                The events add on the instances is same as add on instance
                
                onClck and onPointerOver is two different event. both need to stopPtopagation.
                 */
                onClick={(event) => { event.stopPropagation() }}
                onPointerOver={(event) => { event.stopPropagation() }}

            >
                <meshBasicMaterial
                    map={roadMap}
                    reflectivity={0.01}
                />
                {/* 
                <meshStandardMaterial
                    transparent={false}
                    opacity={0.9}
                    color={0x71cdff}
                    roughness={0.8}

                /> */}

                {locations.map((pos, inx) => (
                    <Instance
                        key={`road${id}_${inx}`}
                        position={pos}

                    />
                ))}
            </Instances>
            <Instances
                geometry={nodes[`lots${id}`].geometry}
                receiveShadow
                castShadow

                onClick={(event) => { event.stopPropagation() }}
                onPointerOver={(event) => { event.stopPropagation() }}
            >
                <meshBasicMaterial
                    map={roadMap}
                    reflectivity={0.01}
                />

                {/* <meshStandardMaterial
                    transparent={false}
                    opacity={0.9}
                    color={0x71cdff}
                    roughness={0.8}
                /> */}

                {locations.map((pos, inx) => (
                    <Instance
                        key={`lots${id}_${inx}`}
                        position={pos} />
                ))}
            </Instances>


            <Instances
                geometry={nodes[`building${id}`].geometry}
                receiveShadow
                castShadow

                onClick={(event) => { event.stopPropagation() }}
                onPointerOver={(event) => { event.stopPropagation() }}

            >

                {/* <MeshTransmissionMaterial
                    transmission={0.7}
                    thickness={0.5}
                    roughness={0.2}
                    distortion={0.3}
                    anisotropy={0.5}
                    color={0xffb671}
                /> */}
                <meshBasicMaterial
                    map={buildingMap}
                    reflectivity={0.01}
                />
                {locations.map((pos, inx) => (
                    <Instance
                        key={`building${id}_${inx}`}
                        position={pos}
                    />
                ))}
            </Instances>

            {nodes[`water${id}`] &&
                <Instances
                    geometry={nodes[`water${id}`].geometry}
                    receiveShadow
                    castShadow

                    onClick={(event) => { event.stopPropagation() }}
                    onPointerOver={(event) => { event.stopPropagation() }}
                >
                    <meshStandardMaterial
                        transparent={true}
                        opacity={0.9}
                        color={0x71cdff}
                        roughness={0.1}
                    />
                    {locations.map((pos, inx) => (
                        <Instance
                            key={`water${id}_${inx}`}
                            position={pos} />
                    ))}
                </Instances>

            }
        </group>


    )
}




