export default function InformationRow(props) {
    const informations = props.informations

    return <div className="inform_area">
        <div className="item_name">
            {informations.map((info, inx) => (
                <div className="item_name_each" key={inx} >{info.name}</div>
            ))}
        </div>
        <div className="item_text">
            {informations.map((info, inx) => (
                <div key={inx} >{info.text}</div>
            ))}
        </div>

    </div>
}