import * as THREE from 'three'

/*
Calculate location matrix for instanceMesh.
Getting a position array and return an array with  three.js Vector3 objects.
 
*/
export default function LocationCalculation(arr) {
    const offset_num = 6

    const position = {
        x: arr[0],
        y: arr[1],
        z: arr[2]
    }

    const result = []
    result.push(new THREE.Vector3(position.x, position.y, position.z))



    if (position.x > 0 && position.z > 0) {
        result.push(new THREE.Vector3(position.x - 0 * offset_num, position.y, position.z - 2 * offset_num))
        result.push(new THREE.Vector3(position.x - 2 * offset_num, position.y, position.z - 0 * offset_num))
        result.push(new THREE.Vector3(position.x - 2 * offset_num, position.y, position.z - 2 * offset_num))
    } else if (position.x > 0 && position.z < 0) {
        result.push(new THREE.Vector3(position.x - 2 * offset_num, position.y, position.z + 0 * offset_num))
        result.push(new THREE.Vector3(position.x - 0 * offset_num, position.y, position.z + 2 * offset_num))
        result.push(new THREE.Vector3(position.x - 2 * offset_num, position.y, position.z + 2 * offset_num))
    } else if (position.x < 0 && position.z < 0) {
        result.push(new THREE.Vector3(position.x + 2 * offset_num, position.y, position.z + 0 * offset_num))
        result.push(new THREE.Vector3(position.x + 0 * offset_num, position.y, position.z + 2 * offset_num))
        result.push(new THREE.Vector3(position.x + 2 * offset_num, position.y, position.z + 2 * offset_num))
    } else if (position.x < 0 && position.z > 0) {
        result.push(new THREE.Vector3(position.x + 2 * offset_num, position.y, position.z - 0 * offset_num))
        result.push(new THREE.Vector3(position.x + 0 * offset_num, position.y, position.z - 2 * offset_num))
        result.push(new THREE.Vector3(position.x + 2 * offset_num, position.y, position.z - 2 * offset_num))
    }

    return result

}