import { Environment } from "@react-three/drei";
import { useControls } from "leva";
import { useRef } from "react";

export default function EnvironmentLight(props) {
    const env = useRef()

    return <>
        <Environment

            // background
            files="./static/textures/hdrmap/small_hangar_01_1k.hdr"

        />

        {/* <ambientLight intensity={0.2} /> */}
    </>
}