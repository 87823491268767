
import * as THREE from 'three'


/**
 * all model processing functions and classes
 */
export default class MaterialManager {
    constructor() {
        this.textureLoader = new THREE.TextureLoader()


        this.product = new THREE.MeshStandardMaterial({
            metalness: 1,
            roughness: 0.1,
            color: this.getColor("product")
        })

        this.special = new THREE.MeshStandardMaterial({
            metalness: 1,
            roughness: 0.1,
            color: this.getColor("special")
        })

        this.empty = new THREE.MeshStandardMaterial({
            metalness: 1,
            roughness: 0.1,
            color: this.getColor("empty")
        })

        this.computer_graphic = new THREE.MeshStandardMaterial({
            metalness: 1,
            roughness: 0.1,
            color: this.getColor("computer_graphic")
        })

        this.architecture = new THREE.MeshStandardMaterial({
            metalness: 1,
            roughness: 0.1,
            color: this.getColor("architecture")
        })

        this.programing = new THREE.MeshStandardMaterial({
            metalness: 1,
            roughness: 0.1,
            color: this.getColor("programing")
        })



    }



    //get the type corresbonding color
    getColor(type) {

        //product color setting
        const basicColor = 0xffffff
        const specialColor = 0x019dfb
        const cgColor = 0x9b6213
        const archColor = 0x09d73d
        const programingColor = 0xd30101
        const productColor = 0xe62fff

        if (type === "empty") {
            return basicColor
        }
        if (type === "special") {
            return specialColor
        }
        else if (type === "computer_graphic") {
            return cgColor
        }
        else if (type === "architecture") {
            return archColor
        }
        else if (type === "programing") {
            return programingColor
        }
        else if (type === "product") {
            return productColor
        }

    }

    //based on project type, return a material with correspond color
    getMaterial(type) {


        switch (type) {
            case "product": return this.product
            case "special": return this.special
            case "empty": return this.empty
            case "computer_graphic": return this.computer_graphic
            case "architecture": return this.architecture
            case "programing": return this.programing


            default: return this.empty

        }

    }








}