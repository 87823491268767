import LoadingPage from './Pages/LoadingPage';
import useLoadingProgress from './Utils/useLoadingProgress';

export default function LoadingPageWrap(props) {
    const loadFinish = useLoadingProgress((state) => state.finished)

    return <>
        {!loadFinish && <LoadingPage />}

    </>
}