/*
Auto-generated by: https://github.com/pmndrs/gltfjsx
*/

import React, { useRef, useMemo, useContext, createContext, useEffect, useState } from 'react'
import { useGLTF, Merged, useAnimations } from '@react-three/drei'
import * as THREE from 'three'
import { useFrame } from '@react-three/fiber'
import useProjectColor from '../Utils/useProjectColor'
import useModalPage from '../Utils/useModalPage'

// Create  context to pass  and models betweeen major merge wrape and model
const context = createContext();

/*
Outside wrape of the project model, only run once

provide <Merge> at the outter. initial load the model with GLTF loader,
save the loaded nodes and animation in the memory, so when rerender will not load.

initial context add function, add model and animation in different context.
*/
export function About({ children, ...props }) {
    const { nodes } = useGLTF(props.resource.model);  //load model as nodes to divide it into individual components
    /**save model into memo */
    const instances = useMemo(
        () => ({
            Name: nodes.Name,
        }),
        [nodes]
    );




    return (
        /**
         * return the merged wrap
         * add material in here as props
         */

        <Merged meshes={instances} {...props}>
            {(instances) => (
                <context.Provider value={instances} children={children} />
            )}
        </Merged>
    );
}

/**
 * Creating instance function
 * pass the basic instance location/rotation/... as props. generate instance.
 * we can also pass animated ralated property to control animation speed or others.
 */
export function AboutModel(props) {
    /**Global state 
     * 
     *prepare for click open modal page effect.
    */
    const showModal = useModalPage((state) => state.setModalPage)//the open modal page function.

    // read instanced model from context
    const instances = useContext(context);

    /**
 * model color information
 * 
 * to set the color on instance, the component set a color mutiply factor called 'color' on each instance
 * the factor will mutiply with instance color number to get the final color
 * so in here, we try to find the original color's reciprocal to set the color to white
 */
    const color = new THREE.Color(props.color)//model color

    /*
    The color can be 0, so 1/0 problem will show
    */
    const whiteColorFactor = new THREE.Color(1 / color.r, 1 / color.g, 1 / color.b)//find the factor to set instance color to white
    const originalColorFactor = new THREE.Color(1, 1, 1)//set the color back to original model color

    /**
  * set ref on individual model pieces
  */
    const name = useRef()


    /**
 * Hover color change animation
 * 
 * use an state number to monitor the hover condition
 * if hover, set the hover situation to true
 * 
 * useFrame to set the color change animationl. and use lerp to set the vector change fade effect
 */
    const [hovered, setHover] = useState(false)

    useFrame(() => {
        // console.log(name.current);
        name.current.color.lerp(color.set(hovered ? whiteColorFactor : originalColorFactor), 0.1)

    })

    /**
   * store all the interact effect as an object
   */
    const effect = {
        onPointerOver: (event) => {
            event.stopPropagation()//stop the raycast
            setHover(true)//change hover state
        },
        onPointerOut: () => {
            setHover(false)//change hover state
        },
        onclick: () => {
            showModal('about')
            // console.log('click about')
        }
    }

    //load animation once when the model load.
    useEffect(() => {

        /**color change animation */
        const unsubscribeProdoctColor = useProjectColor.subscribe(

            (state) => state.otherColor,
            (value) => {
                if (value === false) {
                    setHover(true)
                }
                else if (value === true) {
                    setHover(false)
                }
            }
        )

        /*unsubsctibe Color information when component be recreact*/
        return () => {
            unsubscribeProdoctColor()
        }
    }, [])

    return (
        // return an instance group wrap with different instance in it.
        //we sent postion in prop, so position will be replaced. 
        <group {...props}
            name="Scene"
            onPointerOver={effect.onPointerOver}
            onPointerOut={effect.onPointerOut}
            onClick={effect.onclick}
            dispose={null}
        >
            <instances.Name name="Name" ref={name} />
        </group>
    );
}

useGLTF.preload("/name.glb");