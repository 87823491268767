import { useState } from "react";

export default function ContactModal(props) {
    const [status, setStatus] = useState("Submit")
    const handleSubmit = async (e) => {
        e.preventDefault()
        setStatus("Sending...")
        const { name, email, phone, message } = e.target.elements;
        let details = {
            name: name.value,
            email: email.value,
            phone: phone.value,
            message: message.value,
        }

        let response = await fetch("http://localhost:5000/contact", {
            method: "POST",
            headers: {
                "Content-Type": "application/json;charset=utf-8",
            },
            body: JSON.stringify(details),
        })
        console.log(response);
        setStatus("submit");
        let result = await response.json();

        alert(result.status)
    }

    return <div className="modal_background">
        <div className="close_btn" onClick={props.closeFuntion}>∅</div>
        <div className="contact_page_area">
            <form className="contact_page_form" onSubmit={handleSubmit}>
                <div>
                    <label className="contact_page_type" htmlFor="name">Name:</label>
                    <input className="contact_page_input" type="text" id="name" required />
                </div>
                <div>
                    <label className="contact_page_type" htmlFor="email">Email:</label>
                    <input className="contact_page_input" type="email" id="email" required />
                </div>
                <div>
                    <label className="contact_page_type" htmlFor="phone">Phone:</label>
                    <input className="contact_page_input" type="phone" id="phone" />
                </div>
                <div>
                    <label className="contact_page_type" htmlFor="message">Message:</label>
                    <textarea className="contact_page_input" type="message" id="message" required />
                </div>
                <button className="contact_page_submit_btn" type="submit">{status}</button>
            </form>
        </div>
    </div>
}