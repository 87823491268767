
import { useProgress, Html } from "@react-three/drei"
import { useEffect } from "react"
import useLoadingProgress from "./useLoadingProgress"



export default function Loader() {
    const { active, progress, errors, item, loaded, total } = useProgress()

    const setValue = useLoadingProgress((state) => state.setProgress)
    const setFinish = useLoadingProgress((state) => state.setFinish)
    useEffect(() => {
        setValue(progress)
        if (progress === 100) {
            setFinish()
        }
    }, [progress])


    return ""

}

