

import EnvironmentLight from "./VisualElement/EnvironmentLight";
import DebugUI from "./Utils/DebugUI";
import CameraControls from "./Utils/CameraControls";
import Fog from "./VisualElement/Fog";

import { Perf } from "r3f-perf";
import { Suspense } from "react";
import Projects from "./Projects";
import Loader from "./Utils/Loader";

import ProjectPath from "./Utils/ProjectPath";
import Cities from "./Cities";
import MovingCamera from "./Utils/MovingCamera";
import useModalPage from "./Utils/useModalPage";
import { useEffect } from "react";
import LoadingPage from "./Pages/LoadingPage";





export default function Experience(props) {

    const showModal = useModalPage((state) => state.setModalPage)

    const handleHashChang = () => {
        const tag = window.location.hash.substring(1)

        if (tag === 'cities' || tag === 'empty') { console.log('wrong path') }
        else if (ProjectPath[tag]) {
            showModal(tag)
        }
    }
    useEffect(() => {
        if (window.location.hash) {
            handleHashChang()
        }
        window.addEventListener("hashchange", handleHashChang)
    })




    return <>

        {/* <LoadingProgress /> */}

        {/* <Perf position="top-left" /> */}
        {/* <DebugUI /> */}
        {/* <directionalLight position={[5, 20, 10]} castShadow /> */}

        <CameraControls />

        <Suspense fallback={<Loader />}>

            {/* Environment */}
            <EnvironmentLight />
            <Fog />

            {/* city model load */}
            <Cities resource={ProjectPath.cities} />

            {/* project model loader */}
            <Projects materialManager={props.materialManager} />

        </Suspense>
        <MovingCamera />

    </>
}
