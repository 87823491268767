import CityModel from "./Projects/CityModel"


/*
Load base city Model
*/
export default function Cities(props) {
    // console.log(CityModel_new);
    const resource = props.resource

    // console.log(props.resource);
    return <>
        {resource.map((city, index) => (
            <CityModel
                key={`city0${index + 1}`}
                resource={city}

            />
        ))}

    </>
}