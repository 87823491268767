/*
Auto-generated by: https://github.com/pmndrs/gltfjsx
Command: npx gltfjsx@6.1.3 public/static/models/projects/colorWheel.glb --instanceall
*/

import React, { useRef, useMemo, useContext, createContext, useEffect, useState } from 'react'
import { useGLTF, Merged, useAnimations } from '@react-three/drei'
import * as THREE from 'three'
import { useFrame } from '@react-three/fiber'
import useProjectColor from '../Utils/useProjectColor'
import useModalPage from '../Utils/useModalPage'

// Create two contexts to pass animation and models betweeen major merge wrape and model
const modelContext = createContext()
const animationContext = createContext()

/*
Outside wrape of the project model, only run once

provide <Merge> at the outter. initial load the model with GLTF loader,
save the loaded nodes and animation in the memory, so when rerender will not load.

initial context add function, add model and animation in different context.
*/
export function ColorWheel({ children, ...props }) {
  //load model as nodes to divide it into individual components
  // Load animations
  // console.log(props.model);
  const { nodes, animations } = useGLTF(props.model)


  // save animation in to memo
  const savedAnimation = useMemo(() => ({
    animation: animations
  }),
    [animations]
  )
  /**
   * assign material to different model part before instanciate them
   */


  // save model into memo
  const models = useMemo(
    () => ({
      Cylinder: nodes.Cylinder,
      Cube: nodes.Cube,
    }),
    [nodes]
  )

  // console.log(models.Cylinder);
  // console.log(material);


  return (

    // {/* // return the merged wrap */}
    < Merged meshes={models}  {...props} >

      {/* After returning the wrap, processing an function to add models and animations to different context

    we have to send models in the here, because <Merged> already instanced the models. 
    we need to provide instantiated model reference to the context, so we add "model" in the parameter
    in the childern, we send material as prop.

    We can't instanciate animations, so animations will just use the call function animation.
*/}
      {
        (models) => <>

          {/* two provide has to wrap on each other */}
          <modelContext.Provider value={models} children={children} >
            <animationContext.Provider value={savedAnimation} children={children} />
          </modelContext.Provider>
        </>
      }

    </Merged >

  )
}

/**
 * Creating instance function
 * pass the basic instance location/rotation/... as props. generate instance.
 * we can also pass animated ralated property to control animation speed or others.
 */
export function ColorWheelModel(props) {
  /**Global state */
  const showModal = useModalPage((state) => state.setModalPage)


  // read property from context
  const instances = useContext(modelContext)
  const animationArray = useContext(animationContext)



  /**
   * model color information
   * 
   * to set the color on instance, the component set a color mutiply factor called 'color' on each instance
   * the factor will mutiply with instance color number to get the final color
   * so in here, we try to find the original color's reciprocal to set the color to white
   */
  const color = new THREE.Color(props.color)//model color

  const whiteColorFactor = new THREE.Color(1 / color.r, 1 / color.g, 1 / color.b)//find the factor to set instance color to white
  const originalColorFactor = new THREE.Color(1, 1, 1)//set the color back to original model color

  /**
   * Hover color change animation
   * 
   * use an state number to monitor the hover condition
   * if hover, set the hover situation to true
   * 
   * useFrame to set the color change animationl. and use lerp to set the vector change fade effect
   */
  const [hovered, setHover] = useState(false)

  useFrame(() => {
    // console.log(cube.current);
    cylinder.current.color.lerp(color.set(hovered ? whiteColorFactor : originalColorFactor), 0.1)
    cube.current.color.lerp(color.set(hovered ? whiteColorFactor : originalColorFactor), 0.1)
  })

  const group = useRef()// set reference model group

  /**
   * set ref on individual model pieces
   */
  const cylinder = useRef()
  const cube = useRef()

  /**
   * store all the interact effect as an object
   */
  const effect = {
    onPointerOver: (event) => {
      event.stopPropagation()//stop the raycast
      setHover(true)//change hover state
    },
    onPointerOut: () => {
      setHover(false)//change hover state
    },
    onclick: () => { showModal('colorWheel') }
  }


  const animation = useAnimations(animationArray.animation, group) // sent animation clip and model scene to the hook

  //load animation once when the model load.
  useEffect(() => {
    const action = animation.actions.CubeAction //read action from animationclip
    action.play()//animation start

    //set animation play speed
    action.timeScale = 0.25

    const unsubscribeProdoctColor = useProjectColor.subscribe(

      (state) => state.productColor,
      (value) => {
        if (value === false) {
          setHover(true)
        }
        else if (value === true) {
          setHover(false)
        }
      }
    )

    return () => {
      unsubscribeProdoctColor()
    }
  }, [])

  return (
    // return an instance group wrap with different instance in it.

    //we sent postion in prop, so position will be replaced. 
    <group ref={group} {...props} dispose={null} >
      <group
        name="Scene"
        animations={animationArray.animation}
        onPointerOver={effect.onPointerOver}
        onPointerOut={effect.onPointerOut}
        onClick={effect.onclick}

      >


        <instances.Cylinder name="Cylinder" ref={cylinder} />
        <instances.Cube name="Cube" ref={cube} />

      </group>
    </group>
  )
}


