import ProjectPath from "../../Utils/ProjectPath"
import { useState } from "react";
import InformationRow from "./InformationRow";
import ProjectTitle from "./ProjectTitle";
import SlidesBtn from "./SlidesBtn";
import { animated, useSpring, useTransition } from "react-spring";

export default function ProjectModal(props) {
    /**Local data */
    const projectData = ProjectPath[`${props.project}`]
    const images = projectData.images
    const title = projectData.title
    const informations = projectData.informations
    const text = projectData.text

    /*local state */
    const [picNo, setPicNo] = useState(0)// check current picture number

    const currentImage = images[picNo]


    /*point btn click effect*/
    const changeImage = (num) => {
        setPicNo(num)
    }

    /*pre and next image btn click effect*/
    const preImage = () => {
        if (picNo < 1) {
            setPicNo(images.length - 1)
        }
        else {
            setPicNo(picNo - 1)
        }
    }

    const nextImage = () => {
        if (picNo >= images.length - 1) {
            setPicNo(0)
        }
        else {
            setPicNo(picNo + 1)
        }
    }

    /*Touch slide effct*/
    const [touchPosition, setTouchPosition] = useState(null)
    const handleTouchStart = (e) => {
        const touchDown = e.touches[0].clientX
        setTouchPosition(touchDown)
    }
    const handleTouchMove = (e) => {
        const touchDown = touchPosition
        if (touchDown === null) { return }

        const currentTouch = e.touches[0].clientX
        const diff = touchDown - currentTouch

        if (diff > 5) {
            preImage()
        }

        else if (diff < -5) {
            nextImage()

        }

        setTouchPosition(null)
    }

    /*Animation*/
    /**
    * image Transition Animation: Mount/Unmount
    */
    const image_transition = useTransition(picNo, {
        from: { opacity: 0 },
        enter: { opacity: 1 },
        config: { duration: 1000 }

    })

    const title_transition = useSpring({
        from: { x: -1000 },
        to: { x: 0 }
    })

    const textBox_transition = useSpring({
        from: { y: 1000 },
        to: { y: 0 }
    })




    return <div className="modal_background">
        <div className="close_btn" onClick={props.closeFuntion}>∅</div>

        <div className="slide_area">

            <div className="pic_box">
                <div className="btn_image_next" onClick={preImage}>{"<"}</div>
                {image_transition((style) => (
                    <animated.div className="image" style={style}><img src={currentImage.path} onTouchMove={handleTouchMove} onTouchStart={handleTouchStart} /></animated.div>
                ))
                }
                <div className="btn_image_next" onClick={nextImage}>{">"}</div>
            </div>

            {image_transition((style) => (
                <animated.div className="image_title" style={style} > {currentImage.name}</animated.div>
            ))
            }


            <div className="slide_choice">
                {images.map((img, inx) => (
                    <SlidesBtn
                        key={img.name}
                        index={inx}
                        clickFunction={() => { changeImage(inx) }}
                        picNo={picNo}
                    />
                ))}

            </div>
        </div>
        <animated.div style={title_transition} >
            <ProjectTitle title={title} />
        </animated.div>


        <animated.div style={textBox_transition} className="text_area">

            <div className="intro">
                <p>{text}</p>
            </div>
            <InformationRow informations={informations} />

        </animated.div>
    </div>

}